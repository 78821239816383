import './App.css';
import { Route, SingleRouter } from './components/SingleRouter/SingleRouter';
import { AppContainer } from './index/index';
import { UploadContainer } from './upload/upload';
import { LogInContainer } from './login/login';
import { ReviewContainer } from './admin/review/review';
import { Component } from 'react';
import { UserContext } from './helper/Context';

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      name: '',
      role: 0
    };
    this.setUserData = this.setUserData.bind(this);
  }

  setUserData({ name, role, avatar }) {
    this.setState({ name, role, avatar });
  }

  render() {
    return (
      <UserContext.Provider value={{ userData: this.state, setUserData: this.setUserData }}>
        <SingleRouter>
          <Route path="/" component={AppContainer} />
          <Route path="/upload" component={UploadContainer} />
          <Route path="/login" component={LogInContainer} />
          <Route path="/admin/review" component={ReviewContainer} />
        </SingleRouter>
      </UserContext.Provider>
    );
  }
}

export default App;

import { Component } from 'react';
import Spinner from '../components/Spinner/Spinner';
import UploadUnit from '../components/UploadUnit/UploadUnit';
import { post } from '../helper/axios';
import { apis } from '../helper/apis';
import { alert } from '../helper/alert';
import { images } from '../resources.json';

import './upload.css';
import UserControl from '../components/UserControl/UserControl';
import { Link } from '../components/SingleRouter/SingleRouter';

export class UploadContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      file: null,
      msg: "",
      url: "",
      submitting: false
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleUploadError = this.handleUploadError.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleChange(e) {
    const { files } = e.target;
    if (files.length > 0)
      this.setState({ file: files[0] });
  }

  handleUploadError(msg) {
    this.setState({ submitting: false });
  }

  handleSubmit() {
    if (this.file === null || this.state.submitting) return;
    this.setState({ submitting: true });
    localStorage.removeItem('msgTemp');
  }

  componentDidUpdate() {
    if (this.state.submitting && (!this.state.file || this.state.url !== "")) {
      post(apis.submitMessage, { content: this.state.msg, image: this.state.url })
        .then(({ data, status, networkStatus }) => {
          this.setState({ submitting: false });
          if (networkStatus !== 200) return;
          if (!status) {
            return alert('提交内容失败：' + data);
          }
          this.setState({ msg: "", url: "", file: null });
          alert('内容提交成功啦');
        });
    }
  }

  componentDidMount() {
    if (localStorage.getItem('msgTemp')) {
      this.setState({ msg: localStorage.getItem('msgTemp') });
    }
  }

  render() {
    return (
      <div className="upload-container">
        <div className="banner">
          <div className="sdu">
            <img src={images.icon} className="sdu-logo" alt="logo" />
            <img src={images.name} className="sdu-name" alt="" />
            <div className="nav">
              <div className="nav-item">
                <Link to="/" className="nav-link">首页</Link>
              </div>
            </div>
          </div>
          <UserControl pageAuthLevel={1} />
        </div>
        <div className="content">
          <div className="message-box">
            <textarea
              className="message-box-textarea"
              placeholder="你的留言…"
              value={this.state.msg}
              onChange={e => {
                this.setState({ msg: e.target.value });
                localStorage.setItem('msgTemp', e.target.value);
              }}
              disabled={this.state.submitting}
            />
          </div>
          <div className="upload-box">
            {
              this.state.file
              ? (
                <UploadUnit
                  file={this.state.file}
                  onUpload={url => this.setState({ url })}
                  onUploadError={this.handleUploadError}
                  onCancel={() => this.setState({ file: null, url: "" })}
                  upload={this.state.submitting}
                />
              )
              : (
                <div className="upload-image-btn">
                  <input type="file" accept="image/*" multiple={false} onChange={this.handleChange} />
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 60 54"><path d="M60.342,102.091l5.331-8a2.249,2.249,0,0,1,1.872-1h18a2.246,2.246,0,0,1,1.872,1l5.332,8h7.094a6.72,6.72,0,0,1,6.7,6.7v31.6a6.722,6.722,0,0,1-6.7,6.7h-46.6a6.722,6.722,0,0,1-6.7-6.7v-31.6a6.72,6.72,0,0,1,6.7-6.7h7.094Zm5.407,0H87.341l-3-4.5H68.751l-3,4.5Zm10.8,35.25a12.75,12.75,0,1,0-12.75-12.75A12.764,12.764,0,0,0,76.545,137.341Zm0-21a8.25,8.25,0,1,1-8.25,8.25A8.261,8.261,0,0,1,76.545,116.341Z" transform="translate(-46.546 -93.091)" fill="#8A8A8A"/></svg>
                  <div className="upload-image-btn-text">添加图片</div>
                </div>
              )
            }
          </div>
          <button
            className="btn btn-sdu btn-straight btn-upload"
            onClick={this.handleSubmit}
            disabled={this.state.file === null || this.state.submitting}
          >
            {
              this.state.submitting
              ? (<Spinner />)
              : '提交'
            }
          </button>
        </div>
      </div>
    );
  }
}
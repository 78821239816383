import { Component } from 'react';
import { apis } from '../helper/apis';
import { post } from '../helper/axios';
import History from '../helper/history';
import Spinner from '../components/Spinner/Spinner';
import './login.css';

export class LogInContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      username: '',
      password: '',
      processing: false,
      error: false,
      errorMessage: '',
    };
  }

  handleChange(e) {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  }

  handleSubmit(e) {
    e.preventDefault();
    const { username, password } = this.state;
    if (!username)
      return this.setState({ error: true, errorMessage: '学号不能为空' });
    if (!password)
      return this.setState({ error: true, errorMessage: '密码不能为空' });
    if (!username.match(/^\d*$/))
      return this.setState({ error: true, errorMessage: '学号必须为数字' });
    this.setState({ processing: true });
    post(apis.login, { username, password }).then(({ data, status, networkStatus }) => {
      this.setState({ processing: false });
      if (networkStatus !== 200) return;
      if (!status) return this.setState({ error: true, errorMessage: data });
      localStorage.setItem('jwt', data);
      History.replace('/');
    });
  }

  render() {
    const { error, errorMessage, processing } = this.state;
    return (
      <div className="login-container">
        <h1>登录</h1>
        <form onSubmit={this.handleSubmit.bind(this)}>
          <div className="form-group">
            <input
              type="text"
              name="username"
              className="form-control"
              placeholder="学号"
              onChange={this.handleChange.bind(this)}
            />
          </div>
          <div className="form-group">
            <input
              type="password"
              name="password"
              className="form-control"
              placeholder="密码"
              onChange={this.handleChange.bind(this)}
            />
          </div>
          <div className="submit-btn-wrap">
            <button type="submit" className="btn btn-straight btn-sdu" disabled={processing || !this.state.username || !this.state.password}>
              {
                processing
                ? (
                  <Spinner />
                )
                : "登录"
              }
            </button>
          </div>
          {
            error &&
              <div className="alert">
                {errorMessage}
              </div>
          }
        </form>
      </div>
    );
  }
}
import { backEndBaseURL } from '../env.json';
export const apis = {
  uploadImage: "https://image.kieng.cn/upload.html?type=jd",

  login: backEndBaseURL + "/user/login",
  getProfile: backEndBaseURL + "/user/me",
  uploadAvatar: backEndBaseURL + "/user/uploadAvatar",
  updateNickname: backEndBaseURL + "/user/updateNickname",

  submitMessage: backEndBaseURL + "/post/submit",
  listEssence: backEndBaseURL + "/post/listPublished?page=1",

  listNotReviewed: backEndBaseURL + "/admin/list/1",
  setStatus: backEndBaseURL + "/admin/setStatus"
};
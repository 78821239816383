import Swal from 'sweetalert2';

const SelfSwal = Swal.mixin({
  customClass: {
    confirmButton: 'btn btn-primary btn-pill',
    cancelButton: 'btn btn-dark btn-hollow btn-pill',
  },
  buttonsStyling: false,
});

// ajax
export const success = content => {
  return SelfSwal.fire({
    html: content,
    showConfirmButton: false,
    timer: 1000,
    icon: 'success'
  });
}
export const failed = (content, afterRetry) => {
  return afterRetry ?
    SelfSwal.fire({
      html: content,
      showCancelButton: true,
      confirmButtonText: '重试',
      cancelButtonText: '放弃',
      icon: 'error'
    })
    .then(result => result.isConfirmed ? afterRetry() : null) :
    SelfSwal.fire({
      html: content,
      showConfirmButton: false,
      timer: 1000,
      icon: 'error'
    })
    .then(() => null);
}

export const alert = (content) => {
  return SelfSwal.fire({
    html: content,
    confirmButtonText: '了解',
    icon: 'info'
  });
}

export const confirmWithClose = async (content) => {
  return (await SelfSwal.fire({
    html: content,
    confirmButtonText: '确定',
    showCancelButton: true,
    cancelButtonText: '取消',
    showCloseButton: true,
  })).isConfirmed;
}

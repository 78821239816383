import './spinner.css';

export default function Spinner({ isGray }) {
  let base = isGray ? '888' : 'FFF';
  return (
    <div className="spinner">
      <svg viewBox="0 0 1027 1024" version="1.1" xmlns="http://www.w3.org/2000/svg">
        <path d="M101.725953 198.235466m48.271823-48.271823l0 0q48.271823-48.271823 96.543646 0l144.815469 144.815468q48.271823 48.271823 0 96.543646l0 0q-48.271823 48.271823-96.543646 0l-144.815469-144.815468q-48.271823-48.271823 0-96.543646Z" fill={`#${base}D`}></path>
        <path d="M341.367467 443.733333m0 68.266667l0 0q0 68.266667-68.266667 68.266667l-204.8 0q-68.266667 0-68.266667-68.266667l0 0q0-68.266667 68.266667-68.266667l204.8 0q68.266667 0 68.266667 68.266667Z" fill={`#${base}C`}></path>
        <path d="M343.085068 584.40542m48.271823 48.271823l0 0q48.271823 48.271823 0 96.543646l-144.815469 144.815468q-48.271823 48.271823-96.543646 0l0 0q-48.271823-48.271823 0-96.543646l144.815469-144.815468q48.271823-48.271823 96.543646 0Z" fill={`#${base}B`}></path>
        <path d="M443.767467 682.666667m68.266666 0l0 0q68.266667 0 68.266667 68.266666l0 204.8q0 68.266667-68.266667 68.266667l0 0q-68.266667 0-68.266666-68.266667l0-204.8q0-68.266667 68.266666-68.266666Z" fill={`#${base}A`}></path>
        <path d="M584.439553 680.949066m48.271823-48.271823l0 0q48.271823-48.271823 96.543646 0l144.815469 144.815468q48.271823 48.271823 0 96.543646l0 0q-48.271823 48.271823-96.543646 0l-144.815469-144.815468q-48.271823-48.271823 0-96.543646Z" fill={`#${base}9`}></path>
        <path d="M1024.034133 443.733333m0 68.266667l0 0q0 68.266667-68.266666 68.266667l-204.8 0q-68.266667 0-68.266667-68.266667l0 0q0-68.266667 68.266667-68.266667l204.8 0q68.266667 0 68.266666 68.266667Z" fill={`#${base}8`}></path>
        <path d="M825.798668 101.69182m48.271823 48.271823l0 0q48.271823 48.271823 0 96.543646l-144.815469 144.815468q-48.271823 48.271823-96.543646 0l0 0q-48.271823-48.271823 0-96.543646l144.815469-144.815468q48.271823-48.271823 96.543646 0Z" fill={`#${base}7`}></path>
        <path d="M443.767467 0m68.266666 0l0 0q68.266667 0 68.266667 68.266667l0 204.8q0 68.266667-68.266667 68.266666l0 0q-68.266667 0-68.266666-68.266666l0-204.8q0-68.266667 68.266666-68.266667Z" fill={`#${base}6`}></path>
      </svg>
    </div>
  );
}
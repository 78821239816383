import './review.css';
import { Component } from 'react';
import UserControl from '../../components/UserControl/UserControl';
import { images } from '../../resources.json';
import { get, post } from '../../helper/axios';
import { apis } from '../../helper/apis';
import { alert, confirmWithClose } from '../../helper/alert';
import { UserContext } from '../../helper/Context';
import Spinner from '../../components/Spinner/Spinner';
import { Link } from '../../components/SingleRouter/SingleRouter';

export class ReviewContainer extends Component {
  static contextType = UserContext;
  fetched = false;
  constructor(props) {
    super(props);
    this.state = {
      posts: [],
      fetchingPosts: false,
      reviewingNow: 0,
      actionPerforming: 0
    };
  }

  componentDidMount() {
    if (this.context.userData?.role === 2) this.fetchPosts();
  }

  componentDidUpdate() {
    if (this.context.userData?.role === 2 && !this.fetched) this.fetchPosts();
  }

  fetchPosts() {
    this.fetched = true;
    this.setState({ fetchingPosts: true });
    get(apis.listNotReviewed).then(({ data, status, networkStatus }) => {
      this.setState({ fetchingPosts: false });
      if (networkStatus !== 200) return;
      if (!status) return alert('拉取审核列表失败：' + data + '，请刷新重试');

      this.setState({ posts: data });
    });
  }

  async review(accepted) {
    // 哇提示语怎么这么生硬啊
    if (!await confirmWithClose(`<div class="expanded-msg">确认<b>${accepted ? '发布' : '不通过'}</b>此条信息？</div>`)) return;
    const { posts, reviewingNow } = this.state;
    const { id } = posts[reviewingNow];
    this.setState({ actionPerforming: accepted ? 1 : 2 });
    post(apis.setStatus, { id, status: accepted ? 2 : 3 }).then(({ data, status, networkStatus }) => {
      this.setState({ actionPerforming: 0 });
      if (networkStatus !== 200) return;
      if (!status) return alert('提交审核失败：' + data + '，请稍后重试');

      if (this.state.reviewingNow === this.state.posts.length - 1)
        this.fetchPosts();
      else
        this.setState({ reviewingNow: this.state.reviewingNow + 1 });
    });
  }

  render() {
    return (
      <div className="review-container">
        <div className="banner">
          <div className="sdu">
            <img src={images.icon} className="sdu-logo" alt="logo" />
            <img src={images.name} className="sdu-name" alt="" />
            <div className="nav">
              <div className="nav-item">
                <Link to="/" className="nav-link">首页</Link>
              </div>
            </div>
          </div>
          <UserControl pageAuthLevel={2} />
        </div>
        <div className="content">
          {
            this.state.fetchingPosts
            ? (
              <div className="col-center">
                <Spinner isGray />
                <div style={{ marginLeft: '10px' }}>正在加载审核列表...</div>
              </div>
            )
            : (
              this.state.posts.length === 0
              ? (
                <div className="col-center">暂时没有待审核的内容哦</div>
              )
              : (
                <div className="review-content">
                  <p>{this.state.posts[this.state.reviewingNow].content}</p>
                  <a className="review-img" href={this.state.posts[this.state.reviewingNow].image} target="_blank" rel="noreferrer">
                    <img src={this.state.posts[this.state.reviewingNow].image} alt="待审核的配图" />
                  </a>
                  <div className="review-btns">
                    <button className="btn btn-primary" onClick={() => this.review(true)} disabled={this.state.actionPerforming !== 0}>
                      {this.state.actionPerforming === 1 ? (<Spinner />) : '发布'}
                    </button>
                    <button className="btn btn-gray" onClick={() => this.review(false)} disabled={this.state.actionPerforming !== 0}>
                      {this.state.actionPerforming === 2 ? (<Spinner />) : '不通过'}
                    </button>
                  </div>
                </div>
              )
            )
          }
        </div>
      </div>
    );
  }
}
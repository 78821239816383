import { Component } from 'react';
import ImagePreview from '../components/ImagePreview/ImagePreview';
import { Link } from '../components/SingleRouter/SingleRouter';
import Spinner from '../components/Spinner/Spinner';
import UserControl from '../components/UserControl/UserControl';
import { alert } from '../helper/alert';
import { apis } from '../helper/apis';
import { get } from '../helper/axios';
import { images } from '../resources.json';
import './index.css';

export class AppContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      posts: [],
      fetchingEssential: false,
    };
  }

  componentDidMount() {
    if (this.state.fetchingEssential) return;
    this.setState({ fetchingEssential: true });
    get(apis.listEssence).then(({ data, status, networkStatus }) => {
      this.setState({ fetchingEssential: false });
      if (networkStatus !== 200) return;
      if (!status) return alert('获取精选列表失败：' + data + '，请刷新重试');
      this.setState({ posts: data });
    });
  }

  render() {
    return (
      <div className="index-container">
        <div className="poster" style={{ backgroundImage: `url(${images.poster})` }}>
          <div className='index-btns'>
            <UserControl pageAuthLevel={1} buttonOnly />
            <Link to="/upload" className="btn btn-light btn-straight btn-partIn">点击参加</Link>
          </div>
        </div>
        <div className="content">
          <div className="card">
            <div className="card-header">
              精选留言
            </div>
            <div className="card-body">
              {
                this.state.fetchingEssential
                ? (
                  <div className="col-center"><Spinner isGray /></div>
                )
                : (
                  this.state.posts.length === 0
                  ? (
                    <div className="col-center">暂时没有精选留言</div>
                  )
                  : (
                    <div className="posts">
                      {
                        this.state.posts.map((post, i) => (
                          <div className="post" key={i}>
                            <div className="post-header">
                              <img class="post-avatar" src={post.avatar} alt="头像" />
                              <div>
                                <div class="post-username">{post.nickname}</div>
                                <div class="post-time">于{new Date(post.time * 1000).toLocaleString()}发布</div>
                              </div>
                            </div>
                            <div className="post-body">
                              <p dangerouslySetInnerHTML={{ __html: post.content }}></p>
                              {
                                post.image && (
                                  <div className="post-image">
                                    <ImagePreview image={post.image} />
                                  </div>
                                )
                              }
                            </div>
                          </div>
                        ))
                      }
                    </div>
                  )
                )
              }
            </div>
          </div>
        </div>
      </div>
    );
  }
}
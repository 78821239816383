import axios from 'axios';
import { failed } from './alert';
import qs from 'qs';
import History from './history';

export function get(url, evoker) {
  return send(
    axios.get(url, {
      headers: {
        Authorization: localStorage.getItem('jwt'),
        "Allow-Control-Allow-Origin": "*"
      }
    }),
    {
      fetcher: () => get(url, evoker),
      identifier: 'get:' + url + (evoker ? `@${evoker}` : '')
    }
  );
}
export function post(url, data, evoker) {
  return send(
    axios.post(url, qs.stringify(data), {
      headers: {
        Authorization: localStorage.getItem('jwt'),
        "Allow-Control-Allow-Origin": "*"
      }
    }),
    {
      fetcher: () => post(url, data, evoker),
      identifier: 'post:' + url + (evoker ? `@${evoker}` : '')
    }
  );
}
export function multiFormPost(url, data, evoker) {
  return send(
    axios.post(url, data, {
      headers: {
        Authorization: localStorage.getItem('jwt'),
        "Allow-Control-Allow-Origin": "*"
      }
    }),
    {
      fetcher: () => multiFormPost(url, data, evoker),
      identifier: 'mfPost:' + url + (evoker ? `@${evoker}` : '')
    }
  );
}

const waitToSend = [];

async function send(xhr, retryConf) {
  if (waitToSend.length) {
    // 等待列表不为空，说明需要等待用户决定是否重试，这也提高了目前请求的成功率
    return await new Promise(res => {
      if (waitToSend.every(retryConfItem => retryConfItem.identifier !== retryConf.identifier))
        waitToSend.push({ ...retryConf, resolver: res });
      else
        waitToSend
          .filter(retryConfItem => retryConfItem.identifier === retryConf.identifier)
          .forEach(retryConfItem => {
            retryConfItem.resolver({ networkStatus: -2, status: false });
            retryConfItem.resolver = res;
          });
          // 虽然说理论上filter的结果只会有一个，但是还是要forEach一下，因为写起来方便hhhh
    });
  }
  try {
    const { data } = await xhr;
    console.log("recv: ", data);
    return {
      ...data,
      networkStatus: 200
    };
  }
  catch (err) {
    const failData = {
      networkStatus: err?.response?.status ?? -1,
      status: false
    };
    if (err?.response?.status === 401) {
      History.force('/login');
      feedWaitList(failData);
      // 若是认证问题导致的错误，则没有必要继续后面等待的请求，且强制跳转后重试可能会导致已卸载的组件被更新
      return failData;
    }

    if (waitToSend.length) return failData;
    waitToSend.push(retryConf);
    console.log(err);
    // 等待列表不为空时弹框要么出现了要么就是在消失的路上，没有办法给予用户点击重试的机会，所以交由外部逻辑处理

    const failPostProceess = () => {
      waitToSend.splice(waitToSend.indexOf(retryConf), 1);
      return failData;
    }
    
    // 注意，理论上带有时间戳的请求是不可以重试的，但是这里不做那方面考虑，如果未来有需要，可以自己实现一个刷新时间戳重试的逻辑
    if (err.message === 'Network Error')
      return (await failed('您的设备似乎断网了，或者服务器发生了问题，请检查网络后重试或刷新', flushWaitList(retryConf))) || failPostProceess();
    if (!err?.response?.status)
      return (await failed('请求发生问题：' + err.message, flushWaitList(retryConf))) || failPostProceess();
    if (err.response.status === 504)
      return (await failed('请求超时，请耐心等待几秒后重试或刷新', flushWaitList(retryConf))) || failPostProceess();
    return (await failed('服务器出现问题，请稍后重试或刷新，错误代码' + err.response.status, flushWaitList(retryConf))) || failPostProceess();
  }
}

function flushWaitList(confToRun) {
  return async () => {
    waitToSend.splice(waitToSend.indexOf(confToRun), 1);
    let fns = waitToSend.map(
      conf =>
        async () => conf.resolver ? conf.resolver(await conf.fetcher()) : await conf.fetcher()
    );
    waitToSend.splice(0, waitToSend.length);
    fns.forEach(fn => fn());
    return confToRun.resolver ? confToRun.resolver(await confToRun.fetcher()) : await confToRun.fetcher()
  };
}

function feedWaitList(data) {
  let fns = waitToSend.map(
    conf =>
      () => conf.resolver(data)
  );
  waitToSend.splice(0, waitToSend.length);
  fns.forEach(fn => fn());
}
import { useEffect, useContext, Fragment } from 'react';

import './userControl.css';
import { images } from '../../resources.json';
import { UserContext } from '../../helper/Context';
import Spinner from '../Spinner/Spinner';
import { get } from '../../helper/axios';
import { apis } from '../../helper/apis';
import { alert } from '../../helper/alert';
import History from '../../helper/history';
import AvatarUnit from '../AvatarUnit/AvatarUnit';

// page level: 0: everyone, 1: login needed, 2: admin only
export default function UserControl({ pageAuthLevel, buttonOnly }) {
  const { userData, setUserData } = useContext(UserContext);
  useEffect(() => {
    if (userData.role === -1) return;
    if (userData.role === 0 && localStorage.getItem('jwt')) {
      // 没有用户信息，但是有登录信息
      setUserData({ role: -1, name: '加载中' });
      get(apis.getProfile).then(({ data, status, networkStatus }) => {
        if (networkStatus !== 200) return;
        if (!status) return alert('获取用户信息失败：' + data + '，请稍候刷新再试');
        setUserData({
          name: data.realName,
          role: data.role,
          avatar: data.avatar,
          nickname: data.nickname
        });
      });
      return;
    }

    if (userData.role < pageAuthLevel) {
      // 用户权限不足，依照页面权限跳转
      if (userData.role === 1) {
        History.force('/');
        alert('您没有权限访问该页面，退出登录后可以使用有权限的账号登录');
        return;
      }
      History.force('/login');
    }

  }, [pageAuthLevel, setUserData, userData]);

  return (
    <UserContext.Consumer>
      {({ userData, setUserData }) => (
        <div className="user">
          {
            userData.role === -1
            ? (
              <Spinner />
            ) : (
              <Fragment>
                {buttonOnly || (
                  <Fragment>
                    {
                      userData.role > 0 && (
                        <AvatarUnit
                          avatar={userData.avatar || images.avatar}
                          onChangeAvatar={url => setUserData({ ...userData, avatar: url })}
                          showTip={!userData.avatar}
                        />
                      )
                    }
                    {
                      userData.role !== 0 && (
                        <div className="user-name">{userData.name}</div>
                      )
                    }
                  </Fragment>
                )}
                <button
                  className="btn btn-hollow btn-straight"
                  onClick={() => {
                    localStorage.setItem('jwt', '');
                    setUserData({ role: 0, name: '' });
                    History.force('/login');
                  }}
                >{userData.role > 0 ? '退出账号' : '登录'}</button>
                {
                  userData.role === 2 && pageAuthLevel !== 2 && (
                    <button
                      className="btn btn-light btn-straight"
                      onClick={() => {
                        History.push('/admin/review');
                      }}
                    >进入审核</button>
                  )
                }
              </Fragment>
            )
          }
        </div>
      )}
    </UserContext.Consumer>
  );
}
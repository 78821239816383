import './Frame.css';
import { Component } from 'react';
import History from '../../helper/history';

export class SingleRouter extends Component {
  historyRemover = null;
  constructor(props) {
    super(props);
    this.state = {
      page: null
    };
  }

  componentDidMount() {
    this.historyRemover = History.init(() => {
      this.setState({
        page: this.getPage()
      });
    });
    this.setState({
      page: this.getPage()
    });
  }

  componentWillUnmount() {
    this.historyRemover?.();
  }

  getPage() {
    const path = History.getHref();
    return this.props.children.filter(component => 
      component && (!component.props.path ||
      path === component.props.path ||
      path.replace(/[\d]*$/, ':id') === component.props.path)
    );
  }

  render() {
    return (
      <div className="full-window">
        {this.state.page}
      </div>
    );
  }
}

export function Route(props) {
  return (
    <div path={props.path}>
      {props.children ?? <props.component />}
    </div>
  );
}

export function Redirect(props) {
  if (History.getHref().match(props.from))
    History.force(props.to);
  return null;
}

export function Link(props) {
  return (<a href={`#${props.to}`} {...props}>{props.children}</a>);
}